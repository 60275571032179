import { render, staticRenderFns } from "./FacetsSelector.vue?vue&type=template&id=606f3e92&scoped=true&"
import script from "./FacetsSelector.vue?vue&type=script&lang=ts&"
export * from "./FacetsSelector.vue?vue&type=script&lang=ts&"
import style0 from "./FacetsSelector.vue?vue&type=style&index=0&id=606f3e92&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "606f3e92",
  null
  
)

export default component.exports